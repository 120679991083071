import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress, Typography, Collapse, Box } from '@mui/material';
import { MailOutlineRounded, DoneRounded } from '@mui/icons-material';
import api from '@bamboo/core-lib/api';
import { notify } from '@bamboo/core-lib/repo/notification';

const Root = styled('section')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '95%',
  padding: '0px 0px 0px 13px',
  margin: '70px 0',
  paddingLeft: '20px',
  paddingRight: '20px',
  [theme.breakpoints.down('lg')]: {
    margin: '10px 0 0',
    padding: '0 25px 4px 25px',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 25px 4px 25px',
    maxWidth: 450,
    marginTop: 0,
  },
}));

const PrimaryContainer = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '47.058%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('md')]: {
    width: '400px',
    alignSelf: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    alignSelf: 'center',
  },
}));

const ImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  padding: '0px',
  borderRadius: '23px',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  margin: '0px 5px 0px 0px',
  boxShadow: 'rgba(0, 0, 0, 0.27) -9px 9px 48px 0px',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: 'url("/images/home.jpg")',
  backgroundSize: 'cover',
  alignContent: 'flex-end',
  alignItems: 'flex-end',
  [theme.breakpoints.down('lg')]: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: '100%',
    height: '400px',
  },
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
    justifySelf: 'center',
    maxWidth: 400,
    maxHeight: 400,
    width: 'calc(100vw - 50px)',
    height: 'calc(100vw - 50px)',
  },
}));

const PrimaryTitleContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  marginBottom: '10px',
  padding: '32px 32px 0px',
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
    left: '35px',
    bottom: '80px',
    padding: '0',
    marginBottom: '0px',
  },
}));

const PrimaryTitle = styled('h2')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  color: 'rgb(255, 255, 255)',
  fontSize: '30px',
  fontWeight: 700,
  lineHeight: '27px',
  whiteSpace: 'break-spaces',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    lineHeight: '1',
  },
}));

const PrimaryDescriptionContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: '0px 32px 51px',
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
    left: '35px',
    bottom: '35px',
    padding: '0',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginTop: '30px',
  },
}));

const PrimaryDescription = styled('h2')(({ theme }) => ({
  padding: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 1,
  color: 'rgb(255, 255, 255)',
  fontSize: '20px',
  fontWeight: 400,
  whiteSpace: 'break-spaces',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
    fontWeight: 600,
    marginBottom: '.9rem',
  },
}));

const SecondaryContainer = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '52.942%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: 50,
  },
}));

const FormContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  padding: '7% 20% 7% 9%',
  alignContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    marginTop: '30px',
    padding: '6px 6px 6px 6px',
  },
}));

const SecondaryTitleContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0% 0% 2%',
  [theme.breakpoints.down('md')]: {
    margin: '0',
  },
}));
const SecondaryTitle = styled('h2')(({ theme }) => ({
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  color: 'rgb(58, 58, 58)',
  fontSize: '1.6em',
  fontWeight: 500,
  lineHeight: '30px',
  whiteSpace: 'break-spaces',
  [theme.breakpoints.down('lg')]: {
    paddingRight: '130px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ShortSecondaryTitle = styled('h2')(({ theme }) => ({
  marginTop: '0px',
  marginBottom: '5px',
  color: '#000',
  fontSize: '30px',
  fontWeight: 600,
  lineHeight: '1.2em',
  whiteSpace: 'break-spaces',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const SecondaryDescriptionContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  color: '#727272',
  width: '100%',
  fontSize: '14px',
  fontWeight: 400,
  marginBottom: '5px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const SecondaryDescription = styled('p')(() => ({
  marginTop: '0px',
  marginBottom: '0.9rem',
  whiteSpace: 'break-spaces',
}));

const InputStyled = styled('input')(() => ({
  margin: '0px',
  overflow: 'visible',
  boxSizing: 'border-box',
  lineHeight: 1.4,
  minHeight: '40px',
  padding: '0rem 1rem',
  transition: 'all 0.3s ease 0s',
  width: '100%',
  maxWidth: '100%',
  verticalAlign: 'middle',
  flexGrow: 1,
  color: '#727272',
  fontSize: '13px',
  fontWeight: 400,
  borderRadius: '50px 0px 0px 50px',
  backgroundColor: 'rgb(255, 255, 255)',
  border: '1px solid rgb(194, 194, 194)',
  boxShadow: 'none',
  appearance: 'none',
  borderRight: '0px none',
  '&:focus': {
    outline: '0 none',
  },
}));

const StyledButton = styled('button')(() => ({
  margin: '0px',
  overflow: 'visible',
  textTransform: 'none',
  width: '30px',
  whiteSpace: 'nowrap',
  userSelect: 'none',
  boxSizing: 'border-box',
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  fontSize: '15px',
  fill: 'rgb(255, 255, 255)',
  textAlign: 'center',
  cursor: 'pointer',
  paddingTop: '0px',
  paddingBottom: '0px',
  fontWeight: 500,
  minHeight: '40px',
  flexBasis: '100%',
  borderRadius: '0px 50px 50px 0px',
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgb(194, 194, 194)',
  border: '1px solid rgb(194, 194, 194)',
  boxShadow: 'none',
  appearance: 'none',
  borderLeft: '0px',
  alignSelf: 'flex-end',
  justifySelf: 'flex-end',

  '&: hover': {
    color: '#ff9966',
  },
}));

const WarningContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  textAlign: 'left',
  color: 'rgb(162, 162, 162)',
  fontSize: '12px',
  fontWeight: 400,
  marginTop: '8px',
  marginLeft: '1rem',
  paddingRight: '15px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const DefaultLabels = {
  titlePrimary: `Seus filmes\ncom cara de Brasil`,
  titleSecondary: `Assine nossa newsletter\ne receba clipes e novos\nlançamentos`,
  shortTitleSecondary: `Receba clipes\ne novidades do\nmundo filmmaker`,
  descriptionPrimary: `Tenha orgulho das suas produções\ncom banco de imagens`,
  descriptionSecondary:
    'Coleções, playlists e sugestões de novos clipes\nsempre fresquinhos no seu email',
  warning: 'Prometemos não enviar spams : )',
  successfulRegistration: 'E-mail cadastrado com sucesso!',
  failedToRegistration: 'Falha ao cadastrar e-mail',
};

export type NewsletterProps = {
  labels?: typeof DefaultLabels;
};

const Newsletter = (props: NewsletterProps) => {
  const { labels = DefaultLabels } = props;
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    []
  );

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      e?.stopPropagation?.();
      if (!email.trim()) {
        return;
      }
      setLoading(true);
      api
        .newslettersRegistrationCreate({ email })
        .then(() => setSuccess(true))
        .finally(() => setLoading(false))
        .catch((e) => {
          console.warn(e);
          notify({
            message: labels?.failedToRegistration,
            type: 'error',
            temporary: true,
          });
        });
    },
    [email, labels]
  );

  return (
    <Root>
      <PrimaryContainer>
        <ImageContainer>
          <PrimaryTitleContainer>
            <PrimaryTitle>{labels?.titlePrimary}</PrimaryTitle>
          </PrimaryTitleContainer>
          <PrimaryDescriptionContainer>
            <PrimaryDescription>
              {labels?.descriptionPrimary}
            </PrimaryDescription>
          </PrimaryDescriptionContainer>
        </ImageContainer>
      </PrimaryContainer>
      <SecondaryContainer>
        <FormContainer>
          <SecondaryTitleContainer>
            <SecondaryTitle>{labels?.titleSecondary}</SecondaryTitle>
            <ShortSecondaryTitle>
              {labels?.shortTitleSecondary}
            </ShortSecondaryTitle>
          </SecondaryTitleContainer>
          <SecondaryDescriptionContainer>
            <SecondaryDescription>
              {labels?.descriptionSecondary}
            </SecondaryDescription>
          </SecondaryDescriptionContainer>
          <form style={{ width: '100%', marginTop: 8 }} onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexWrap: 'wrap',
                marginBottom: '-10px',
                maxWidth: '355px',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  minHeight: '1px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  width: '85%',
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  marginBottom: '10px',
                }}
              >
                <InputStyled
                  required
                  type="email"
                  id="form-field-email"
                  placeholder="Email"
                  onChange={handleChangeText}
                />
              </div>
              <div
                style={{
                  minHeight: '1px',
                  position: 'relative',
                  flexWrap: 'wrap',
                  display: 'flex',
                  width: '15%',
                  WebkitBoxAlign: 'end',
                  alignItems: 'flex-end',
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  marginBottom: '10px',
                }}
              >
                <StyledButton type="submit">
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <MailOutlineRounded
                      color="inherit"
                      fontSize="small"
                      style={{
                        alignSelf: 'flex-end',
                        justifySelf: 'flex-end',
                        marginRight: 25,
                      }}
                    />
                  )}
                </StyledButton>
              </div>
            </div>
          </form>
          <Collapse in={success}>
            <Typography
              sx={(theme) => ({
                fontSize: 13,
                display: 'flex',
                alignItems: 'center',
                margin: theme.spacing(1, 0),
              })}
            >
              <Box
                sx={(theme) => ({
                  marginRight: theme.spacing(0.5),
                  height: theme.spacing(2),
                })}
              >
                <DoneRounded
                  color="inherit"
                  style={{ width: 13, height: 13 }}
                />
              </Box>
              {labels?.successfulRegistration}
            </Typography>
          </Collapse>
          <WarningContainer>
            <p
              style={{
                marginTop: '0px',
                marginBottom: '0.9rem',
              }}
            >
              {labels?.warning}
            </p>
          </WarningContainer>
        </FormContainer>
      </SecondaryContainer>
    </Root>
  );
};

export default Newsletter;
